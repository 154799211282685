<template>
    <a-modal
        :title="title"
        :visible.sync="visible"
        okText="确定"
        cancelText="取消"
        :width="650"
        @cancel="close()"
        :destroyOnClose="true"
        @ok="validData()"
    >
        <a-spin :spinning="loading">
            <a-form :form="form">
                <a-form-item label="头像" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <upload ref="upload" :length="1" :cutImg="true" :imgs="head" uploadTest="头像" :fixedNumber="[1,1]" @uploadSuccess="headSuccess"></upload>
                </a-form-item>
                <a-form-item label="昵称" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="请输入用户昵称" v-decorator="['name', {rules: ['name',{required: true, min:2, message: '请输入用户昵称'}],initialValue: formData.name, trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="登录账号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-input placeholder="4-16位数字字母下划线" v-decorator="['account', {rules: [{required: true, message: '请输入4-16位账号'},{validator: validAccount}],initialValue: formData.account, trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="登录密码" :labelCol="labelCol" :wrapperCol="wrapperCol" v-if="formData.id === ''">
                    <a-input placeholder="请输入6-18位密码" v-decorator="['pwd', {rules: ['pwd',{required: true, min:6, max: 16, message: '请输入6-18位密码'}],initialValue: formData.pwd, trigger: 'blur'}]"></a-input>
                </a-form-item>
                <a-form-item label="选择角色" :labelCol="labelCol" :wrapperCol="wrapperCol">
                    <a-select
                        mode="tags"
                        placeholder="请选择角色"
                        style="width: 100%"
                        @change="roleChange"
                        v-decorator="['roleIds', {rules: [{required: true, message: '请至少选择一个角色'}],initialValue: formData.roleIds, trigger: 'blur'}]"
                    >
                        <a-select-option v-for="(item) in roles" :key="item.id + ''">{{ item.roleName }}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-spin>
    </a-modal>
</template>
<script>
import md5 from 'js-md5'
import Upload from "@/components/upload/Upload"
export default {
    components: {
        Upload
    },
    data () {
        return {
            title: '添加用户',
            visible: false,
            loading: false,
            roleLoading: false,
            form: this.$form.createForm(this),
            labelCol: { lg: { span: 4 }, sm: { span: 18 } },
            wrapperCol: { lg: { span: 17 }, sm: { span: 10 } },
            head: [],
            roles: [],
            formData: {
                id: '',
                account: '',
                name: '',
                pwd: '',
                head: '',
                schoolId: '',
                roleIds: []
            }
        }
    },
    methods: {
        show (userAccount) {
            if (userAccount == null) {
                this.title = '添加用户'
                this.formData.roleIds = []
            } else {
                this.title = '修改用户'
                let jsonObj = JSON.parse(JSON.stringify(userAccount))
                this.formData = jsonObj
                let roles = jsonObj.roles
                this.formData.roleIds = []
                roles.forEach(role => {
                    this.formData.roleIds.push(role.id + '')
                })
                let head = jsonObj.head
                if (head != null) {
                    let randomNum = Math.floor(Math.random() * 100000)
                    const file = {
                        uid: randomNum + '',
                        name: randomNum + '.png',
                        status: 'done',
                        url: head,
                        response: { data: head }
                    }
                    this.head.push(file)
                }
            }
            this.visible = true
            this.queryRole()
        },
        queryRole () {
            this.roleLoading = true
            this.$api.role.findRolesByUser(this.formData.schoolId).then(res => {
                if (res.status === 0) {
                    this.roles = res.data
                } else {
                    this.$message.error(res.msg)
                }
            }).catch(err => {
                this.$message.error(err)
            }).finally(_ => {
                this.roleLoading = false
            })
        },
        close () {
            this.visible = false
            this.formData = {
                id: '',
                account: '',
                name: '',
                pwd: '',
                head: '',
                schoolId: '',
                roleIds: []
            }
            this.head = []
            this.roles = []
        },
        validData () {
            this.form.validateFields((error, values) => {
                if (!error) {
                    if (this.head.length > 0) {
                        this.formData['head'] = this.head[0].url
                    }
                    this.formData.name = values['name']
                    this.formData.account = values['account']
                    if (this.formData.id === '') {
                        this.formData.pwd = md5(values['pwd'])
                        this.add()
                    } else {
                        this.update()
                    }
                }
            })
        },
        headSuccess (e) {
            this.head = e
        },
        roleChange (value) {
            this.formData.roleIds = value
            this.form.setFieldsValue({ roleIds: value })
        },
        add () {
            this.loading = true
            this.$api.userAccount.add(this.formData).then(res => {
                this.handlerSuccess(res)
            }).catch(err => {
                this.handlerError(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        update () {
            this.loading = true
            this.$api.userAccount.update(this.formData).then(res => {
                this.handlerSuccess(res)
            }).catch(err => {
                this.handlerError(err)
            }).finally(_ => {
                this.loading = false
            })
        },
        handlerSuccess (res) {
            if (res.status === 0) {
                this.$message.success(res.msg)
                this.$emit('success')
                this.close()
            } else {
                this.$message.error(res.msg)
            }
        },
        handlerError (err) {
            this.$message.error(err)
        },
        validAccount (rule, value, callback) {
            let accountReg = new RegExp(/^[0-9a-zA-Z_]{4,16}$/)
            if (value && !accountReg.test(value)) {
                callback('账号由4-16位数字字母下划线组成');
            } else {
                callback()
            }
        }
    }
}
</script>
